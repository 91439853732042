import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as viewPowerMeteringAction from '../../actions/viewPowerMetering';
import * as viewMoterAnalysis from '../../actions/viewMotorAnalysis';
import NumericLable from 'react-pretty-numbers';
import TopNavigation from "../common/topNavigation";
import Excitation from "../common/ExcitationComponent";
import config from "../../api/config";
import * as logOffAction from '../../actions/logOffAction';
import SecTopHeader from "../common/SecTopHeader";
import Options from "../common/more_options";
import equipmentApi from '../../api/equipement';

class PowerAnalysisPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showOptions: false,
            nodeData: [
				{
					serialNumber: '---',
					ipAddress: '---',
					macAddress: '---',
				},
			],
        };
    }

    toggleOptions() {
        this.setState({ showOptions: !this.state.showOptions });

    }

    componentDidMount() {

        if (window.equPortfolioPageInterval !== undefined) {
            clearTimeout(window.equPortfolioPageInterval);
        }

        if (window.powerMeterAdminPageInterval !== undefined) {
            clearTimeout(window.powerMeterAdminPageInterval);
        }

        $('.loaderPosition').removeClass('hidden');
        if (this.props.analysis.serialNumber !== undefined) {
            this.interval = setInterval(this.refreshPage, 900000);
            this.props.actionViewPowerMetering.setIntervalOne(this.interval);
        }

        //To terminate the effect of last pending thread of power metering call
        setTimeout(() => this.props.actionViewMotorAnalysis.loadMotorAnalysis(undefined), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    refreshPage = () => {
        this.props.actionViewMotorAnalysis.loadMotorAnalysis(undefined);
    };

    logOff = () => {
        this.props.logOffAction.logOut();
    };

    _changeIconStatus = () => {
		this.setState((prevState) => {
			return { ...prevState, showNodeData: !prevState.showNodeData };
		});
		if (!this.state.showNodeData) {
			equipmentApi.getNodeNetworkConfigs(this.props.token, this.props.selectedSerialNumber).then((data) => {
				this.setState((prevState) => {
					return { ...prevState, nodeData: data };
				});
			});
		}
	};

    applyClassName = (name) => {
        if (name !== undefined && name.length > 15) {
            return "eqType ";
        } else {
            return "eqType utilityText";
        }
    };

    render() {

        let option = {
            'justification': 'R',
            'locales': 'en-AU',
            'percentage': false,
            'precision': config.datapoint_precision,
            'commafy': true,
            'title': true,
            'cssClass': ['pr5']
        };


        console.log('Analysis', this.props);

        setTimeout(function () {
            $('.loaderPosition').addClass('hidden');
        }, 500);

        return (

            <div className="reportPage">
                <div className="wrapperH">

                    <div className="loginH clearfix">
                        <div className="logosmallH">
                            <a href="#">&nbsp;</a>
                        </div>
                        <div className="logoff ">
                            <div className="three_line" onClick={this.toggleOptions.bind(this)}>&nbsp;</div>
                        </div>
                    </div>

                    <div className="clearfix">
                        <div>
                            {this.state.showOptions ?
                                <div className="options_menu">
                                    <Options />
                                </div>
                                : null}
                        </div>
                        <TopNavigation activeNode={4}/>
                        <SecTopHeader name={this.props.analysis.name} serialNumber={this.props.analysis.serialNumber}
                                      facilityName={this.props.analysis.facilityName}/>
                        <div className="blueBoxH bdRadius spmb30">
                            <div className="titleH clearfix">

                                <div className="subTitleH">
                                    <span className="performH allCaps">POWER ANALYSIS</span>
                                </div>

                                <div className="dateTimeH">
                                    <span className="waveHdRight"><span
                                        className="spldbBL">performed: </span> {this.props.analysis.displayTimeElapsed} </span>
                                </div>

                                {/* MOTOR ANALYSIS <span className="performH">performed {this.props.analysis.displayTimestamp} </span> */}

                            </div>
                            <div className="clearfix">
                                <div className="specialWrapper">
                                    <div className="meterH">
                                        <div className="padLefRigH">
                                            <div className="rectH">
                                                <ul>
                                                    <li>
                                                        <span className="rectHead minMl50">Voltage</span>
                                                        <span className="goldBox dbi splBdRadius">
                                                            <span className="defaultAlert splDefaultAlert">
                                                            <span className="splMaAlert target_element9"/>
                                                         </span>

                                                            {this.props.analysis.equipmentType === 'UtilityMain' ?
                                                                (this.props.analysis.voltageLine !== null ? <span
                                                                className="greenTxt splPl1 minWid75"><NumericLable
                                                                params={option}>{this.props.analysis.voltageLine}</NumericLable></span> :
                                                                <span
                                                                className="greenTxt splPl1 minWid75"><NumericLable
                                                                params={option}>{"---"}</NumericLable></span>)
                                                                :
                                                                (this.props.analysis.voltage !== null ? <span
                                                                        className="greenTxt splPl1 minWid75"><NumericLable
                                                                        params={option}>{this.props.analysis.voltage}</NumericLable></span> :
                                                                    <span
                                                                        className="greenTxt splPl1 minWid75"><NumericLable
                                                                        params={option}>{"---"}</NumericLable></span>)
                                                            }

                                                            <span className="perSign wid26">Volts</span>
                                                     </span>
                                                    </li>
                                                    <li>
                                                        <span className="rectHead minMl10">Current</span>
                                                        <span className="goldBox dbi splBdRadius">
                                                            <span className="defaultAlert splDefaultAlert">
                                                            <span className="splMaAlert target_element10">&nbsp;</span>
                                                         </span>

                                                            {
                                                                this.props.analysis.current !== null ? <span
                                                                        className="greenTxt splPl1 minWid75"><NumericLable
                                                                        params={option}>{this.props.analysis.current}</NumericLable></span> :
                                                                    <span
                                                                        className="greenTxt splPl1 minWid75"><NumericLable
                                                                        params={option}>{"---"}</NumericLable></span>
                                                            }

                                                            <span className="perSign wid26">Amps</span>
                                                     </span>
                                                    </li>
                                                    <li>
                                                        <span className="rectHead minMl10">Power Factor</span>
                                                        <span className="goldBox dbi splBdRadius">
                                                            <span className="defaultAlert splDefaultAlert">
                                                            <span className="splMaAlert target_element11">&nbsp;</span>
                                                         </span>
                                                            {this.props.analysis.equipmentType === 'UtilityMain' ?(
                                                                this.props.analysis.rawPowerFactorLine !== null ? <span
                                                                    className="greenTxt splPl1 minWid75"><NumericLable
                                                                    params={option}>{this.props.analysis.rawPowerFactorLine}</NumericLable></span> :
                                                                <span
                                                                    className="greenTxt splPl1 minWid75"><NumericLable
                                                                    params={option}>{"---"}</NumericLable></span>
                                                            ):(
                                                                this.props.analysis.powerFactor !== null ? <span
                                                                    className="greenTxt splPl1 minWid75"><NumericLable
                                                                    params={option}>{this.props.analysis.powerFactor}</NumericLable></span> :
                                                                <span
                                                                    className="greenTxt splPl1 minWid75"><NumericLable
                                                                    params={option}>{"---"}</NumericLable></span>
                                                            )
                                                            }
                                                            <span className="perSign wid26">PF</span>
                                                    </span>
                                                    </li>
                                                    <li>
                                                        <span className="rectHead minMl50">Frequency</span>
                                                        <span className="goldBox dbi splBdRadius">
                                                            <span className="defaultAlert splDefaultAlert">
                                                            <span className="splMaAlert target_element12">&nbsp;</span>
                                                         </span>

                                                            {
                                                                this.props.analysis.lineFrequency !== null ? <span
                                                                        className="greenTxt splPl1 minWid75"><NumericLable
                                                                        params={option}>{this.props.analysis.lineFrequency}</NumericLable></span> :
                                                                    <span
                                                                        className="greenTxt splPl1 minWid75"><NumericLable
                                                                        params={option}>{"---"}</NumericLable></span>
                                                            }

                                                            <span className="perSign wid26">Hz</span>
                                                    </span>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="circleH splHgt254">
                                                <div className="firstCir">
                                                    <div className="secCir">
                                                        <div className="thirdCir">
                                                            <div className='divposition'>
                                                                <div
                                                                    className={this.applyClassName(this.props.analysis.displayEquipmentType)}> {this.props.analysis.displayEquipmentType !== undefined ? this.props.analysis.displayEquipmentType : ""}</div>
                                                                {
                                                                    this.props.analysis.equipmentType === 'UtilityMain' ? (
                                                                        (this.props.analysis.rawPowerKw !== undefined && this.props.analysis.rawPowerKwLine !== null) ?
                                                                        <span
                                                                            className="goldBoxforInsideCircle splRadisForgoldBoxInsideCircle voltageFont mainPowerReading">
                                                                            <span className="dis">
                                                                            <NumericLable
                                                                                params={option}>{this.props.analysis.rawPowerKwLine}</NumericLable></span>
                                                                            <span className="kwFont">kW</span>

                                                                        </span> : <span
                                                                            className="goldBoxforInsideCircle splRadisForgoldBoxInsideCircle voltageFont mainPowerReading">
                                                                            <span className="dis"><NumericLable
                                                                                params={option}>{"---"}</NumericLable></span>
                                                                            <span className="kwFont">kW</span>
                                                                        </span>
                                                                    ):(
                                                                        (this.props.analysis.powerKw !== undefined && this.props.analysis.powerKw !== null) ?
                                                                        <span
                                                                            className="goldBoxforInsideCircle splRadisForgoldBoxInsideCircle voltageFont mainPowerReading">
                                                                            <span className="dis">
                                                                            <NumericLable
                                                                                params={option}>{this.props.analysis.powerKw}</NumericLable></span>
                                                                            <span className="kwFont">kW</span>

                                                                        </span> : <span
                                                                            className="goldBoxforInsideCircle splRadisForgoldBoxInsideCircle voltageFont mainPowerReading">
                                                                            <span className="dis"><NumericLable
                                                                                params={option}>{"---"}</NumericLable></span>
                                                                            <span className="kwFont">kW</span>
                                                                        </span>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.props.analysis.equipmentType !== undefined &&
                            this.props.analysis.equipmentType === "SynchronousMotor" &&
                            <Excitation showContent="SyncMoterAnalysis"
                                        fieldCurrent={this.props.analysis.fieldCurrent}
                                        fieldCurrentDeltaLower={this.props.analysis.fieldCurrentDeltaLower}
                                        fieldCurrentDeltaUpper={this.props.analysis.fieldCurrentDeltaUpper}
                                        fieldVoltage={this.props.analysis.fieldVoltage}
                                        fieldVoltageDeltaLower={this.props.analysis.fieldVoltageDeltaLower}
                                        fieldVoltageDeltaUpper={this.props.analysis.fieldVoltageDeltaUpper}/>
                        }
                        {(this.props.analysis.equipmentType !== undefined &&
						this.props.analysis.equipmentType === "UtilityMain")? (
						<div className="blueBoxH bdRadius table-responsive spmb30">
							<div className="specialWrapper">
							<table className="table table-condensed borderless measureTble deltaH equalCells">
								<thead>
								<tr>
									<th>&nbsp;</th>
									<th>Active</th>
									<th>
									<span className="deltaTxt">Apparent</span>
									</th>
									<th>
									<span className="deltaTxt">Reactive</span>
									</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td colSpan="4" className="mt3 dbBL" />
								</tr>
								<tr>
									<td className="valueTxt txt-left ">
									<span className="adjusTxt splFontForPap">
										Power
									</span>
									</td>
									<td className="text-right">
									<span className="goldBox splBdRadius splDesk splWidhtForTable">
										{this.props.analysis.rawPowerKwLine !== null ? (
										<span className="greenTxt text-right">
											<NumericLable params={option}>
											{this.props.analysis.rawPowerKwLine}
											</NumericLable>
										</span>
										) : (
										<span className="greenTxt text-right">
											<NumericLable params={option}>
											{"---"}
											</NumericLable>
										</span>
										)}
										<span className="perSign wid48">kW</span>
									</span>
									</td>
									<td>
									<span className="goldBox splBdRadius splDesk splWidhtForTable">
										{this.props.analysis.kvaLine !== null ? (
										<span className="greenTxt text-right">
											<NumericLable params={option}>
											{this.props.analysis.kvaLine}
											</NumericLable>
										</span>
										) : (
										<span className="greenTxt text-right">
											<NumericLable params={option}>
											{"---"}
											</NumericLable>
										</span>
										)}
										<span className="perSign wid48">kVA</span>
									</span>
									</td>
									<td>
									<span className="goldBox splBdRadius splDesk splWidhtForTable">
										{this.props.analysis.kvarLine !== null ? (
										<span className="greenTxt text-right">
											<NumericLable params={option}>
											{this.props.analysis.kvarLine}
											</NumericLable>
										</span>
										) : (
										<span className="greenTxt text-right">
											<NumericLable params={option}>
											{"---"}
											</NumericLable>
										</span>
										)}
										<span className="perSign wid48">kVAR</span>
									</span>
									</td>
								</tr>
								<tr>
									<td colSpan="4" className="mt3 dbBL" />
								</tr>
								</tbody>
							</table>
							</div>
						</div>
						) : null}

                        <div className="blueBoxH bdRadius table-responsive spmb30">
                            <div className="specialWrapper">
                                <table className="table table-condensed borderless measureTble deltaH imbalancH">
                                    <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>Imbalance</th>
                                        <th><span className="centerTxt">THD</span></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td colSpan="3" className="mt3 dbBL"/>
                                    </tr>
                                    <tr>
                                        <td className="valueTxt txt-left"><span className="adjusTxt">Voltage</span></td>
                                        <td className="txtCenter">
                                                     <span className="goldBox splBdRadius ml6 splWid130">
                                                         {
                                                             this.props.analysis.voltageImbalance !== null ?
                                                                 <span className="defaultAlert">
                                                                    <span
                                                                        className="maAlert target_element1">&nbsp;</span>
                                                                 </span> : null
                                                         }
                                                         {
                                                            this.props.analysis.equipmentType === "UtilityMain" ?

                                                            (this.props.analysis.voltageImbalance !== null ?
                                                                <span className="greenTxt wid71"> <NumericLable
                                                                    params={option}>{this.props.analysis.voltageImbalanceLine}</NumericLable> </span> :
                                                                <span className="greenTxt"> <NumericLable
                                                                    params={option}>{"---"}</NumericLable> </span>)
                                                             : 
                                                             (this.props.analysis.voltageImbalance !== null ?
                                                                 <span className="greenTxt wid71"> <NumericLable
                                                                     params={option}>{this.props.analysis.voltageImbalance}</NumericLable> </span> :
                                                                 <span className="greenTxt"> <NumericLable
                                                                     params={option}>{"---"}</NumericLable> </span>)

                                                         }
                                                         {
                                                             this.props.analysis.voltageImbalance !== null ?
                                                                 <span className="perSign"> %</span> : null
                                                         }
                                                     </span>
                                        </td>
                                        <td className="txtCenter">
                                                     <span className="goldBox splBdRadius splWid130">
                                                         <span>
                                                             {
                                                                 this.props.analysis.voltageThd !== null ?
                                                                     <span className="defaultAlert">
                                                                              <span
                                                                                  className="maAlert target_element2">&nbsp;</span>
                                                                     </span> : null
                                                             }
                                                             {
                                                                this.props.analysis.equipmentType === "UtilityMain" ?

                                                                 (this.props.analysis.voltageThd !== null ?
                                                                    <span className="greenTxt wid71"> <NumericLable
                                                                        params={option}>{this.props.analysis.voltageThdLine}</NumericLable> </span> :
                                                                    <span className="greenTxt"> <NumericLable
                                                                        params={option}>{"---"}</NumericLable> </span>)
                                                                :
                                                                 (this.props.analysis.voltageThd !== null ?
                                                                     <span className="greenTxt wid71"> <NumericLable
                                                                         params={option}>{this.props.analysis.voltageThd}</NumericLable> </span> :
                                                                     <span className="greenTxt"> <NumericLable
                                                                         params={option}>{"---"}</NumericLable> </span>)
                                                             }

                                                             {
                                                                 this.props.analysis.voltageThd !== null ?
                                                                     <span className="perSign"> %</span> : null
                                                             }
                                                         </span>
                                                     </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="valueTxt txt-left"><span className="adjusTxt">Current</span></td>
                                        <td className="txtCenter">
                                                     <span className="goldBox splBdRadius ml6 splWid130">
                                                         {
                                                             this.props.analysis.currentImbalance !== null ?
                                                                 <span className="defaultAlert">
                                                                    <span
                                                                        className="maAlert target_element3">&nbsp;</span>
                                                                 </span> : null
                                                         }
                                                         {
                                                             this.props.analysis.currentImbalance !== null ?
                                                                 <span className="greenTxt wid71"> <NumericLable
                                                                     params={option}>{this.props.analysis.currentImbalance}</NumericLable> </span> :
                                                                 <span className="greenTxt"> <NumericLable
                                                                     params={option}>{"---"}</NumericLable> </span>
                                                         }

                                                         {
                                                             this.props.analysis.currentImbalance !== null ?
                                                                 <span className="perSign"> %</span> : null
                                                         }
                                                     </span>
                                        </td>
                                        <td className="spMb5 dbBL txtCenter">
                                                     <span className="goldBox splBdRadius splWid130">
                                                         {
                                                             this.props.analysis.currentThd !== null ?
                                                                 <span className="defaultAlert">
                                                                    <span
                                                                        className="maAlert target_element4">&nbsp;</span>
                                                                </span> : null
                                                         }

                                                         {
                                                             this.props.analysis.currentThd !== null ?
                                                                 <span className="greenTxt wid71"> <NumericLable
                                                                     params={option}>{this.props.analysis.currentThd}</NumericLable> </span> :
                                                                 <span className="greenTxt"> <NumericLable
                                                                     params={option}>{"---"}</NumericLable> </span>
                                                         }
                                                         {
                                                             this.props.analysis.currentThd !== null ?
                                                                 <span className="perSign"> %</span> : null
                                                         }
                                                     </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="clearfix">
                            <div className="blueBoxH bdRadius table-responsive">
                                <div className="specialWrapper">
                                    <table className="table table-condensed ">
                                        <thead>
                                        <tr>
                                            <th className="txtLeft"><span className="ml20 ratingMainHeader">Name Plate Data</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className="ratingHeader">Ratings</td>
                                        </tr>
                                        <tr>
                                            <td className="noTopBorder">
                                                <table className="ratingFullWid">
                                                    <tr>
                                                        <td className="ratingH">
                                                            <table>
                                                                <tr>
                                                                    <td>Line Frequency</td>
                                                                    <td>=</td>
                                                                    <td className="txt-right">{this.props.analysis.npLineFrequency}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Voltage</td>
                                                                    <td>=</td>
                                                                    <td className="txt-right">{this.props.analysis.npVoltage}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Current</td>
                                                                    <td>=</td>
                                                                    <td className="txt-right">{this.props.analysis.npCurrent}</td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='blueBoxH bdRadius spmb30'>
							<div className='clearfix wid100per informationHeader'>
								<div
									className={this.state.showNodeData ? 'equTriangleDownIcon' : 'equTriangleIcon'}
									onClick={() => this._changeIconStatus()}>
									<div className='eqLeftMar'>Node Data</div>
								</div>
							</div>
						</div>
						{this.state.showNodeData ? (
							<div className='clearfix'>
								<div className='blueBoxH bdRadius table-responsive'>
									<div className='specialWrapper'>
										<table className='table table-condensed '>
											<tbody>
												<tr>
													<td className='ratingHeader'>
														S/N: {this.state.nodeData[0].serialNumber}
													</td>
												</tr>
												<tr>
													<td className='noTopBorder'>
														<table className='ratingFullWid'>
															<tr>
																<td className='ratingH'>
																	<table>
																		<tr>
																			<td>IP Address</td>
																			<td>=</td>
																			<td className='txt-right'>
																				{this.state.nodeData[0].ipAddress !== ''
																					? `${this.state.nodeData[0].ipAddress}`
																					: '---'}
																			</td>
																		</tr>
																		<tr>
																			<td>MAC Address</td>
																			<td>=</td>
																			<td className='txt-right'>
																				{this.state.nodeData[0].macAddress !==
																				''
																					? `${this.state.nodeData[0].macAddress}`
																					: '---'}
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
										{this.state.nodeData[1] ? (
											<table className='table table-condensed '>
												<tbody>
													<tr>
														<td className='ratingHeader'>
															S/N: {this.state.nodeData[1].serialNumber}
														</td>
													</tr>
													<tr>
														<td className='noTopBorder'>
															<table className='ratingFullWid'>
																<tr>
																	<td className='ratingH'>
																		<table>
																			<tr>
																				<td>IP Address</td>
																				<td>=</td>
																				<td className='txt-right'>
																					{this.state.nodeData[1]
																						.ipAddress !== ''
																						? `${this.state.nodeData[1].ipAddress}`
																						: '---'}
																				</td>
																			</tr>
																			<tr>
																				<td>MAC Address</td>
																				<td>=</td>
																				<td className='txt-right'>
																					{this.state.nodeData[1]
																						.macAddress !== ''
																						? `${this.state.nodeData[1].macAddress}`
																						: '---'}
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
										) : null}
									</div>
								</div>
							</div>
						) : null}

                        <div className="blueBoxH bdRadius spmb30">
                            <div className="performedFooter">Performed: {this.props.analysis.displayTimestamp}</div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return Object.assign({}, {
        analysis: state.application.analysis.serialNumber !== undefined ? state.application.analysis : {
            "name": state.application.analysis.name,
            "serialNumber": '',
            "timezone": '',
            "voltage": '',
            "current": '',
            "hp": '',
            "rpm": '',
            "lineFrequency": '',
            "runningTime": '',
            "voltageImbalance": '',
            "currentImbalance": '',
            "voltageThd": '',
            "currentThd": '',
            "voltageStDelta": '',
            "currentStDelta": '',
            "hpStDelta": '',
            "voltageLtDelta": '',
            "currentLtDelta": '',
            "hpLtDelta": '',
            "timestamp": '',
            "vaLCValue": '',
            "vbLCValue": '',
            "vcLCValue": '',
            "voltageImbalanceAlert": '',
            "voltageThdAlert": '',
            "currentImbalanceAlert": '',
            "currentThdAlert": '',
            "powerFactor": '',
            "power": '',
            "powerStDelta": '',
            "powerLtDelta": '',
            "torque": '',
            "vDivF": '',
            "voltageAlert": '',
            "currentAlert": '',
            "hpAlert": '',
            "lineFrequencyAlert": '',
            "powerFactorAlert": '',
            "rpmFactorAlert": '',
            "torqueAlert": '',
            "VDivFAlert": '',
            "VaLCAlert": '',
            "VbLCAlert": '',
            "VcLCAlert": '',
            "powerKw": '',
            "powerKwLtDelta": '',
            "powerKwStDelta": ''
        }
    }, state.application);
};

const mapDispatchToProps = dispatch => {
    return {
        actionViewPowerMetering: bindActionCreators(viewPowerMeteringAction, dispatch),
        actionViewMotorAnalysis: bindActionCreators(viewMoterAnalysis, dispatch),
        logOffAction: bindActionCreators(logOffAction, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PowerAnalysisPage);

