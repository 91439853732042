import React from 'react';

export default function SecTopHeader(props) {
    return <div className="blueBoxH bdRadius spmb30">
        <div className="clearfix wid100per informationHeader">
            <div className="locationNamefont1 splColorfortopheader">
                {props.name !== undefined ? props.name : ""}
                {props.secondarySerialNumber ?  ` / Field` : ""}
            </div>
            <div className="serialnofont1">
                {props.serialNumber !== undefined ? `S/N: ${props.serialNumber}` : ""}
                {props.secondarySerialNumber ?  ` / ${props.secondarySerialNumber}` : ""}
            </div>
            <div className="right locationNamefont1">
                {props.facilityName !== undefined ? props.facilityName : ""}
            </div>
        </div>
    </div>;
}

