import React from "react";
import $ from "jquery";
import * as viewPowerMeteringAction from "../../actions/viewPowerMetering";
import * as viewEquipmentPortfolio from "../../actions/viewEquipmentPortfolio";
import * as viewMoterAnalysis from "../../actions/viewMotorAnalysis";
import * as viewWaveForm from "../../actions/waveForm";
import * as viewTrendForm from "../../actions/trendAnalysis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NumericLable from "react-pretty-numbers";
import config from "../../api/config";
import "./EquipmentPortfilio.css";

class EquipmentKW extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.power = "power";
        this.timeoutOne = undefined;
        this.timeoutTwo = undefined;
        this.powerMeteringClick = undefined;
    }

    render() {
        let option = {
            justification: "R",
            locales: "en-AU",
            percentage: false,
            precision: config.datapoint_precision,
            wholenumber: null,
            commafy: true,
            title: true,
        };
        const { node } = this.props;
        const { action } = this.props;
        // console.log("NODE>>>>>>",node)
        const onEquipmentSelection = () => {
            $(".loaderPosition").removeClass("hidden");
            action(node.id);
        };

        const onSubModeMeterLink = () => {
            console.log("onSubModeMeterLink");
            $(".loaderPosition").removeClass("hidden");
            this.props.actionViewPowerMetering.moveSubNodePage(node);
        };

        const onPowerMeterLink = () => {
            clearInterval(window.powerMeterAdminPageInterval);
            clearInterval(window.chartRefreshInterval);
            $(".loaderPosition").removeClass("hidden");
            this.props.actionViewPowerMetering.clearState();
            if (this.powerMeteringClick !== undefined) {
                clearTimeout(this.powerMeteringClick);
            }
            if (node.equipmentType === "UtilityMain") {
                this.powerMeteringClick = setTimeout(
                    function () {
                        if (this.props.noReloadPowerMetering !== true) {
                            this.props.actionViewEquipmentPortfolio.setSelectedDevice(node.id, node.name, node.title);

                            if (this.timeoutOne !== undefined) {
                                clearTimeout(this.timeoutOne);
                            }
                            this.timeoutOne = setTimeout(
                                function () {
                                    this.props.actionTrendForm.loadTrendFormForPowerMeterPageSample(
                                        this.props.selectedNode,
                                        this.power
                                    );

                                    if (this.timeoutTwo !== undefined) {
                                        clearTimeout(this.timeoutTwo);
                                    }

                                    setTimeout(
                                        function () {
                                            this.props.actionViewPowerMetering.loadPowerMeteringAdmin(undefined, true);
                                        }.bind(this),
                                        0
                                    );
                                }.bind(this),
                                0
                            );
                        } else {
                            this.props.actionViewPowerMetering.clearState();
                            this.props.actionViewEquipmentPortfolio.setSelectedDevice(node.id, node.name, node.title);
                            this.timeoutOne = setTimeout(
                                function () {
                                    this.props.actionViewPowerMetering.loadNodeAccToFacility(
                                        this.props.token,
                                        this.props.facilityId
                                    );
                                    this.props.actionTrendForm.loadTrendFormForPowerMeterPageSample(
                                        this.props.selectedNode,
                                        this.power
                                    );

                                    if (this.timeoutTwo !== undefined) {
                                        clearTimeout(this.timeoutTwo);
                                    }
                                    this.timeoutTwo = setTimeout(
                                        function () {
                                            this.props.actionToRefreshingPowerMetering();
                                        }.bind(this),
                                        1000
                                    );
                                }.bind(this),
                                0
                            );
                        }
                    }.bind(this),
                    0
                );
            } else {
                this.props.actionViewEquipmentPortfolio.setSelectedDevice(node.id, node.name);
                setTimeout(
                    function () {
                        this.props.actionViewPowerMetering.clearState();
                        this.props.actionViewPowerMetering.loadPowerMetering(undefined, true);
                    }.bind(this),
                    0
                );
            }
        };

        const onWaveLinkNew = () => {
            clearInterval(window.chartRefreshInterval);
            $(".loaderPosition").removeClass("hidden");
            this.props.actionViewPowerMetering.clearState();
            this.props.actionViewEquipmentPortfolio.setSelectedDevice(node.id, node.name, node.title);
            setTimeout(
                function () {
                    this.props.actionWaveFrom.loadWaveFormNew();
                }.bind(this),
                0
            );
        };

        const onMotorAnalysisLink = () => {
            clearInterval(window.chartRefreshInterval);
            $(".loaderPosition").removeClass("hidden");
            this.props.actionViewPowerMetering.clearState();
            this.props.actionViewEquipmentPortfolio.setSelectedDevice(node.id, node.name, node.title);
            setTimeout(
                function () {
                    this.props.actionViewMotorAnalysis.loadMotorAnalysisMain(node.id);
                }.bind(this),
                0
            );
        };

        const onTrendAnalysisLinkNew = () => {
            clearInterval(window.chartRefreshInterval);

            $(".loaderPosition").removeClass("hidden");
            this.props.actionViewPowerMetering.clearState();
            this.props.actionViewEquipmentPortfolio.setSelectedDevice(node.id, node.name, node.title);

            setTimeout(
                function () {
                    this.props.actionTrendForm.loadTrendFormNew();
                }.bind(this),
                0
            );
        };

        console.log("this.props.hideHeader", this.props.hideHeader, node.type);
        console.log(node);

        return (
            <li>
                <div>
                    <div className="newEqValueHKW newEqValueH bdRadius clearfix">
                        <div className="newEqwH1">
                            <span>{this.props.hideHeader !== true && <h5>{node.title}</h5>}</span>
                        </div>
                        <div
                            className={
                                "newEqBorderKW newEqBorder marforMainBlock" +
                                " " +
                                (node.type === "subNode" ? "marTopBlockEqKw" : "")
                            }
                        >
                            <div className="newEqwH2">
                                <span className="newEqValueKW newEqValue">
                                    {node.name} {node.secondarySerialNumber ? "/ Field" : null}
                                </span>
                            </div>

                            <div className="firstBlock newEqH3">
                                S/N: {node.serialNumber}{" "}
                                {node.secondarySerialNumber ? "/ " + node.secondarySerialNumber : null}
                            </div>
                            {node.type === "SEL" || node.type === "GERelay" ? (
                                <div className="sel-text-box">
                                    Access Volta Analytics For {node.type.replace("GE", "")} Data
                                </div>
                            ) : (
                                <div className="goldBoxForPortfolio newEqH3">
                                    {node.secondarySerialNumber ? (
                                        <>
                                            <span
                                                className={
                                                    "status" +
                                                    this.props.node.connectedStatus
                                                        .replace("ON", "green")
                                                        .replace("OFFLINE", "gray") +
                                                    " eqKwStatus" +
                                                    " newEqStatePrimary"
                                                    // this.props.node.status.toLowerCase(.)
                                                }
                                            ></span>
                                            <span
                                                className={
                                                    "status" +
                                                    this.props.node.secondaryConnectedStatus
                                                        .replace("ON", "green")
                                                        .replace("OFFLINE", "gray") +
                                                    " newEqStateSecondary"
                                                }
                                            ></span>
                                        </>
                                    ) : (
                                        <span
                                            className={
                                                "newEqStateKW status" +
                                                this.props.node.connectedStatus
                                                    .replace("ON", "green")
                                                    .replace("OFFLINE", "gray") +
                                                " newEqState eqKwStatus"
                                                // this.props.node.status.toLowerCase(.)
                                            }
                                        ></span>
                                    )}

                                    <span
                                        className={
                                            "goldBox splRadiusForGoldBox eqKwGoldBoxMar " +
                                            (node.type === "subNode" ? "marfromRight" : "")
                                        }
                                    >
                                        <span className="goldBoxWidth fontSizeForKW">
                                            <span className="dis">
                                                <NumericLable params={option}>
                                                    {(node.power ?? 0).toFixed(config.datapoint_precision)}
                                                </NumericLable>
                                            </span>
                                            <span className="perSign marForKw forkwInside">kW</span>
                                        </span>
                                    </span>
                                </div>
                            )}

                            {node.type === "subNode" ? (
                                <div className="secondBlock marforSecBlock subodePadding">
                                    <div className="topMenuKW topMenu">
                                        <ul>
                                            <li className="subNodeMeterLink" onClick={onSubModeMeterLink}>
                                                <a href="javascript:void(0);">
                                                    <span />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ) : (
                                <div className="secondBlock marforSecBlock marSecBlockForEqKw">
                                    <div className="topMenuKW topMenu">
                                        <ul>
                                            <li
                                                className={
                                                    "powerMeterLink " +
                                                    node.type.replace(/(SEL)|(GERelay)/, "disabled-click gray-effect")
                                                }
                                                onClick={onPowerMeterLink}
                                                disable
                                            >
                                                <a href="javascript:void(0);">
                                                    <span />
                                                </a>
                                            </li>
                                            <li
                                                className={
                                                    "waveFormsLink " +
                                                    node.type.replace(/(SEL)|(GERelay)/, "disabled-click gray-effect")
                                                }
                                                onClick={onWaveLinkNew}
                                            >
                                                <a href="javascript:void(0);">
                                                    <span />
                                                </a>
                                            </li>
                                            <li
                                                className={
                                                    "motorAnalyLink " +
                                                    node.type.replace(/(SEL)|(GERelay)/, "disabled-click gray-effect")
                                                }
                                                onClick={onMotorAnalysisLink}
                                            >
                                                <a href="javascript:void(0);">
                                                    <span />
                                                </a>
                                            </li>
                                            <li
                                                className={
                                                    "trendAnalyLink " +
                                                    node.type.replace(/(SEL)|(GERelay)/, "disabled-click gray-effect")
                                                }
                                                onClick={onTrendAnalysisLinkNew}
                                            >
                                                <a href="javascript:void(0);">
                                                    <span />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return state.application;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionViewEquipmentPortfolio: bindActionCreators(viewEquipmentPortfolio, dispatch),
        actionViewPowerMetering: bindActionCreators(viewPowerMeteringAction, dispatch),
        actionViewMotorAnalysis: bindActionCreators(viewMoterAnalysis, dispatch),
        actionWaveFrom: bindActionCreators(viewWaveForm, dispatch),
        actionTrendForm: bindActionCreators(viewTrendForm, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentKW);
